<template>
  <div class="ui-orders__wrapper-3">
    <aside class="ui-orders__sidebar">
      <div class="overflow-hidden">
        <CRow class="mb-3">
          <CCol col="auto">
            <h3>{{ $t("role.orders.rounds.orders.title") }}</h3>
            <small>({{ $t("role.orders.rounds.orders.subtitle") }})</small>
          </CCol>
          <CCol col="auto">
            <OrdersLegend :statusMap="statusMap" />
          </CCol>
        </CRow>

        <CRow class="justify-content-between mb-3">
          <CCol col="auto">
            <CButton
              color="primary"
              @click="onAllReady"
              :disabled="!canView(PERMS.ORDERS_EDIT)"
              >{{ $t("role.orders.rounds.orders.allDeliveredBtn") }}</CButton
            >
          </CCol>
        </CRow>
      </div>

      <div class="d-flex justify-content-center" v-if="loading">
        <CSpinner color="info" />
      </div>
      <div v-else>
        <CCard
          class="card-selectable mb-2"
          v-for="order in orders"
          :key="order.id"
          :class="cardClasses(order)"
        >
          <CCardBody @click.prevent="selectOrder(order)" class="p-2">
            <CRow class="justify-content-between align-items-center mb-1">
              <CCol>
                <div class="text-truncate">
                  <strong class="mr-1">{{ orderId(order) }}</strong>
                  {{ orderFamilyName(order) }}
                </div>
              </CCol>
              <CCol col="auto">
                <div class="d-flex">
                  <span class="mr-2">
                    entro {{ orderDeliveryDate(order, "dd-MM") }} alle
                    {{ orderDeliveryDate(order, "HH:mm") }}
                  </span>
                  <dropdown-menu
                    :items="actions"
                    :select="(e) => onActionSelect(e, order)"
                    @click.native.stop
                    ><font-awesome-icon icon="ellipsis-h" />
                  </dropdown-menu>
                </div>
              </CCol>
            </CRow>
            <CProgress>
              <CProgressBar
                v-for="progress in ordersProgress(order)"
                :key="progress.id"
                :color="`orders-${progress.color}`"
                :value="progress.percentage"
                >{{ progress.value }}</CProgressBar
              >
            </CProgress>
            <CRow class="justify-content-between align-items-end">
              <CCol col="auto">
                {{ orderProductsCount(order) }} Articoli
                <OrderIncidentBadge
                  class="ml-2"
                  :total="order.incidents"
                  :open="order.incidents_open"
              /></CCol>
              <CCol col="auto" class="text-right">
                <strong
                  >{{ orderTotPrice(order)
                  }}<sup>{{ currencySymbol }}</sup></strong
                >
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard class="mb-2" v-if="!orders.length && !loading">
          <CCardBody> Nessun risultato </CCardBody>
        </CCard>
      </div>
    </aside>
    <main class="ui-orders__main">
      <Detail
        v-if="orderActive"
        :order="orderActive"
        :parentIsLoading="loading"
      />
    </main>
  </div>
</template>

<script>
import { Query } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../../../ds";
import Detail from "./Detail";
import OrdersLegend from "../../../share/OrdersLegend";
import OrderIncidentBadge from "../../../share/OrderIncidentBadge";
import orderManagementMixin from "../../../../../../mixins/orderManagement";
import {
  INCIDENT_ENTITY_TYPE,
  INCIDENT_TYPE,
  ordersProgressMap,
  ORDER_ACTION,
} from "../../../../../../config/global";
import EventBus from "../../../../../../helpers/EventBus";
import { mapGetters, mapState } from "vuex";
import { buildPredicate } from "../../../../../../helpers/common";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";

export default {
  name: "ViewWh",

  mixins: [orderManagementMixin],

  components: {
    Detail,
    OrdersLegend,
    OrderIncidentBadge,
  },

  data() {
    const canEdit = this.canView(PERMS.ORDERS_EDIT);
    return {
      PERMS,
      loading: false,
      orders: [],
      orderActive: null,
      warehouseOptions: [],
      actions: [
        {
          id: ORDER_ACTION.FILTER,
          text: this.$t("role.orders.rounds.orders.actionLabels.filter"),
        },
        {
          id: ORDER_ACTION.DONE,
          text: this.$t("role.orders.rounds.orders.actionLabels.allDelivered"),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.UNDONE,
          text: this.$t(
            "role.orders.rounds.orders.actionLabels.allNotDelivered"
          ),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.INCIDENT,
          text: this.$t("role.orders.rounds.orders.actionLabels.openIncident"),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.CANCELLATION,
          text: this.$t("role.orders.rounds.orders.actionLabels.cancelAll"),
          disabled: !canEdit,
        },
      ],
    };
  },

  watch: {
    round() {
      this.init();
    },
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),

    ...mapState("rounds", ["round"]),

    statusMap() {
      return ordersProgressMap(this.$i18n);
    },
  },

  mounted() {
    this.init();
    EventBus.$on("orders:refresh", this.init);
    EventBus.$on("orders:applyFilters", this.init);
    EventBus.$on("orders:priceUpdate", this.onPriceUpdate);
  },

  beforeDestroy() {
    EventBus.$off("orders:refresh", this.init);
    EventBus.$off("orders:applyFilters", this.init);
    EventBus.$off("orders:priceUpdate", this.onPriceUpdate);
  },

  methods: {
    init() {
      this.getOrders();
    },

    getFilterQuery() {
      const { keyword } = this.$store.state.rounds.filters;

      const map = filtersMap("orders.main.filters");

      let predicate = buildPredicate(
        null,
        map,
        this.$store.state.rounds.filters
      );

      let query = predicate ? new Query().where(predicate) : new Query();

      const searchFields = filtersMap("orders.main.search", []);
      if (keyword && searchFields.length > 0) {
        query = query.search(keyword, searchFields);
      }

      return query;
    },

    getOrders() {
      const self = this;
      const roleId = this.$store.state.role.id;
      const roundId = this.$store.state.rounds.round.id;
      const endpoint = filtersMap("orders.main.endpoint");
      const dm = GetDataManagerNew(endpoint, [roleId, roundId]);
      const query = this.getFilterQuery();
      self.loading = true;
      dm.executeQuery(query)
        .then((response) => {
          self.orders = response.result;
          self.loading = false;

          let selected = null;

          if (self.orderActive) {
            selected =
              self.orders.find(
                (item) => item.order.id === self.orderActive.order.id
              ) || null;
          }

          if (selected === null && self.orders.length > 0) {
            selected = self.orders[0];
          }

          self.selectOrder(selected);
        })
        .catch((response) => {
          console.error(response);
          self.$store.dispatch("toaster/add", {
            title: $t("common.editor.messages.ko.title"),
            text: $t("common.general.loadingError"),
            color: "info",
            autohide: true,
          });
          self.loading = false;
        });
    },

    selectOrder(order) {
      this.orderActive = order;
    },

    cardClasses(order) {
      const isSelected =
        this.orderActive && this.orderActive.order.id === order.order.id;
      const haveError = this.producerDeliveryDateWarning(order);
      return { "card-selected": isSelected, "card-error": haveError };
    },

    onActionSelect(args, order) {
      const orders = [order.id];

      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };

      if (args.item.id === ORDER_ACTION.FILTER) {
        const param = { id: order.order.id, label: order.order.id };
        this.$store.commit("rounds/updateOrderId", param);
        this.init();
      }

      if (args.item.id === ORDER_ACTION.DONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("orders.statusCode.next"),
            orders,
          })
          .then(onSuccess);
      }

      if (args.item.id === ORDER_ACTION.UNDONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("orders.statusCode.prev"),
            orders,
          })
          .then(onSuccess);
      }

      if (args.item.id === ORDER_ACTION.INCIDENT) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [{ type: INCIDENT_ENTITY_TYPE.ORDERS, items: [order] }],
          type: INCIDENT_TYPE.INCIDENT,
          show: true,
        });
      }

      if (args.item.id === ORDER_ACTION.CANCELLATION) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [{ type: INCIDENT_ENTITY_TYPE.ORDERS, items: [order] }],
          type: INCIDENT_TYPE.CANCELLATION,
          show: true,
        });
      }
    },

    onAllReady() {
      const orders = this.orders.map((item) => item.order.id);
      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };
      this.$store
        .dispatch("rounds/updateStatusBatch", {
          status: filtersMap("orders.statusCode.next"),
          orders,
        })
        .then(onSuccess);
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },

    onPriceUpdate() {
      this.init();
    },
  },
};
</script>
